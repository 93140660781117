// Finance report view
.finance-report {
  padding-top: 0;
  margin-top: 0;

  .actions-cell {
    padding-right: 20px !important;
  }

  &__heading {
    background-color: $pale-gray-two;
    padding-bottom: 15px;

    .finance-report__heading-wrapper {
      width: 100%;
    }

    aside {
      margin-top: -5px;
    }

    .status-dot {
      margin-left: 0;
    }

    &-reason{
      color: $warm-gray !important;
      position: relative;
      top: -1px;
      margin-left: 10px;
      font-size: 16px !important;

      &:hover,
      &:active {
        text-decoration: none;
        color: $black !important;
      }
    }
  }

  &__toolbar {
    .fal + span {
      margin-left: 5px;
    }
  }

  &__content {
    margin: 0;
  }

  &__content-wrapper {
    background-color: $white;
    border-radius: $borderRadius;
    box-shadow: $shadow-sm;
    padding: 20px 20px 100px;
    min-height: 1000px;
    position: relative;
    margin-bottom: 50px;

    .finance-report__table-wrapper {
      .react-bs-table-container,
      .finance-report__totals {
        min-width: 1075px;
        padding-right: 0;
      }
    }
  }

  &__organization-logo {
    max-width: 100%;
    max-height: 150px;
  }

  &__meta {
    text-align: right;
    font-size: 14px;
    font-weight: $font-weight-semibold;

    > div > div {
      padding: 4px 0;

      + div {
        border-top: 1px solid $pale-gray;
      }
    }

    label {
      float: left;
      color: $greyish-brown;
      margin-bottom: 0;
      padding: 0;
    }

    span {
      color: $black;
    }
  }

  &__organization-text-logo {
    background-color: $pale-gray !important;
    display: inline-block;
    overflow: hidden;
    padding: 34px 20px;
    font-size: 15px;
    font-weight: $font-weight-semibold;
    text-align: center;
    min-width: 190px;
    float: left;
    max-width: 250px;
  }

  &__summary {
    background: $pale-gray-four;

    > div {
      padding: 14px 0 12px;
      text-align: right;
      &:not(.finance-report__summary-local-amount) {
        box-shadow: 0 1px $white inset;
      }

      > label,
      > span {
        display: inline-block;
        padding: 0 15px;
        width: 330px;
        max-width: 100%;
      }

      > span {
        width: 165px;
      }
    }
    &-total-amount,
    &-local-amount {
      background: $pale-gray;

      > label,
      > span {
        font-weight: $font-weight-semibold;
      }
    }

    &--invoice > div {
      > label {
          width: auto;
      }
    }

    &--approved > div:last-child {
      background-color: $success-bg;

      label,
      span {
        color: $medium-green;
      }
    }

    &--rejected > div:last-child {
      background-color: $error-bg;

      label,
      span {
        color: $tomato-red;
      }
    }

    &--raised,
    &--pending,
    &--amendment-requested,
    &--unresolved {

      > div:last-child {
        background-color: $pending-bg;

        label,
        span {
          color: $pending;
        }
      }
    }
  }

  &__table-wrapper {
    overflow: hidden;

    &--list {
      padding: 0;
      overflow-x: auto;

      .react-bs-table-container,
      .finance-report__totals {
        // min-width: 1100px;
      }
      .finance-report__totals {
        padding-right: 5px;
      }


      .react-bs-table {
        border: none;
      }

      .fileuploader-item {
        max-width: 300px;
      }

      /* specificity is required to override react-bootstrap-table styles */
      tbody > tr.created-by-a-manager,
      tbody > tr.created-by-a-manager:hover {
        background-color: transparentize($pending-orange, .95) !important;

        .created-by-a-manager-icon {
          color: $pending-orange;
        }
      }
    }
  }

  .invoice-table-wrapper,
  .worksheets-table-wrapper {
    margin: 40px 0;

    .react-bs-container-header {
      border-bottom: 1px solid $warm-gray;
    }
  }

  &__organization-footer-info {
    font-size: 14px;
    font-weight: $font-weight-semibold;
    color: $greyish-brown;

    strong {
      font-weight: $font-weight-semibold;
      color: $black;
      display: inline-block;
      margin-bottom: 5px;
    }
  }

  h4 {
    margin: 0 0 5px;
  }

  h5 {
    margin: 5px 0;
  }

  hr {
    border-top-color: $graphite-light;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .finance-report__mobile-actions-menu {
    display: none;
  }
}

.invoice-table {
  .react-bs-table {
    box-shadow: none;

    .table-striped {
      > thead > tr:first-child > th,
      > thead > tr:first-child > td {
        background-color: #fff;
        border-bottom: 1px solid $graphite-light;
      }

      > tbody > tr td {
        border-top: none;
        border-bottom: none;
      }
    }
  }
}

.finance-table__summary {
  background: $pale-gray-two;
  text-align: right;

  > div {
    display: inline-block;
    padding: 15px 10px;
    width: 170px;
    box-shadow: 0 1px #fff inset;

    span {
      display: inline-block;
      color: $black;
      font-weight: $font-weight-semibold;
    }
  }
}

.page--project_add-worksheet .form-container {
  flex-basis: 55%;
}

.page--finance_payment-methods {
  .checkable-list__option {
    display: flex;
    align-items: center;
    height: 65px;
  }
}

.page--finance .service-order-select-project {
  .project-list__item--warning {
    display: none;
    color: $pending;
  }

  .checkable-list__option--disabled {
    border-color: $pale-gray;

    .project-list__item--title {
      color: $warm-gray
    }

    .project-list__item--warning {
      display: block;
    }

    .checkable-list__indicator {
      display: none;
    }
  }
}

.page--project_add-worksheet,
.page--project_claim-expense {
  padding: 0;

  .content-box__header {
    margin-top: 0;

    time {
      color: $warm-gray;
      text-transform: none;
    }
  }

  .form-container {

    .row + .form-group {
      margin-bottom: 0;
    }

    #fieldId-summary {
      height: 100px;
    }
  }

  .CalendarDay__blocked_calendar {
    &, &:active, &:hover {
      background: $pale-gray-five;
      color: $warm-gray;
    }
  }
}

.page--project_add-worksheet {
  .DayPicker {
    margin: 0 auto;
  }

  .CalendarMonthGrid,
  .DayPicker,
  .CalendarMonth,
  .CalendarDay {
    background-color: $pale-gray-two;
  }

  .CalendarDay {
    cursor: default !important;

    &__highlighted_calendar {
      background-color: $medium-green;
    }
  }
}

.worksheet-form, .pro-forma-invoice-form {
  textarea {
    height: 70px;
  }
}

.worksheet-items-table, .task-raise-worksheet-table {
  td,
  th {
    vertical-align: middle;
    border: none;
    padding: 15px 10px;
  }

  th {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    border-bottom: 1px solid $graphite-light;
  }

  &__header--description {
    min-width: 250px;
  }

  &__header--rate-selection {
    min-width: 150px;
  }

  &__header--fixed-amount {
    width: 150px;
  }

  &__header--rate {
    width: 100px;
  }

  &__header--qty {
    width: 220px;
  }

  &__header--amount {
    width: 90px;
  }

  .form-group {
    margin-bottom: 0;
  }

  &__total td {
    border-top: 10px solid $white;
    font-weight: $font-weight-semibold;
    color: $black;
  }

  &__extra td {
    padding-top: 0;
  }
}

.worksheet-items-table {
  td, th {
    & + td:last-child {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.task-raise-worksheet-table {
  td, th {
    &:nth-of-type(1) {
      width: 70px;
    }

    &:nth-of-type(2) {
      width: 28px;
    }

    &:nth-of-type(3) {
      width: 145px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, lg)) {
  .worksheet-items-table, .task-raise-worksheet-table {
    td,
    th {
      padding: 15px 5px;
    }
  }
}


.expense-form {
  .expense-multiplier,
  .worksheet-multiplier {
    font-size: 11px;
    line-height: 35px;
    float: left;
    color: $warm-gray;
    margin: 0 -5px;
  }

  .help-block {
    display: inline-block;
    line-height: 1;
    font-size: 14px;
  }

  #fieldId-quantity + .help-block {
    width: 250%;
  }

  .expense-calculation {
    line-height: 35px;

    &_hourly-rate {
      input {
        background-color: $light-gray-2;
        color: $warm-gray;
        padding-right: 0;
      }
      .input-group-text {
        background-color: $silver;
        color: $warm-gray;
      }
    }

    &_equals {
      color: $warm-gray;
      line-height: 37px;
      font-size: 23px;
    }

    &_total-amount {
      color: $greyish-brown;
      font-size: 14px;
      font-weight: $font-weight-semibold;
    }
  }
}

.worksheet-type-indicator {
  color: $white;
  border-radius: 3px;
  display: inline-block;
  padding: 2px 6px 0;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 15px;
  cursor: default;

  &--ws {
    background-color: $td-blue;
  }

  &--xp {
    background-color: $lightblue-two;
  }

  &--pi {
    background-color: $curious-blue;
  }
}

// exceptions for report views proper UI regarding report width
.finance-report__content-wrapper .invoice-table-wrapper,
.finance-report__table-wrapper.worksheets-table-wrapper,
.finance-report__table-wrapper.expenses-table-wrapper,
.finance-report__table-wrapper.invoice-table-wrapper {
  .finance-report__summary,
  .react-bs-table-container,
  .finance-report__totals {
    min-width: 600px;
  }
}

.finance-report__table-wrapper.purchase-orders-table-wrapper {
  .finance-report__summary,
  .react-bs-table-container,
  .finance-report__totals {
    min-width: 850px;
  }
}



@media (max-width: map-get($grid-breakpoints, md)) {
  #report-view-actions-dropdown {
    button {
      border: none;
      background-color: transparent;
      text-align: center;
      padding-left: 0;
      padding-right: 10px;

      span {
        display: none;
      }

      img {
        opacity: 0.6;
        margin: 0;
      }

      &:hover img {
        opacity: 1;
      }
    }
  }

  .worksheet-calculation,
  .expense-calculation {
    &_total-amount {
      color: $warm-gray !important;
      font-size: 18px!important;
    }
  }

  .finance-report {
    &__content-wrapper {
      min-height: auto;
    }
  }
}

.react-bs-table--responsive {
  & + .static-modal + .finance-report__totals,
  & + .finance-report__totals {
    min-width: auto !important;
    width: 100%;
  }
}

@media (max-width: map-get($grid-breakpoints, xl)) {

  .finance-report__table-wrapper--responsive {
    border: none;
    overflow: visible;

    .finance-table__summary {
      margin-top: 15px;
      border: 1px solid $graphite-light;
      background: $white;

      > div {
        width: 50% !important;
        text-align: left;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .finance-report__summary > .finance-report__summary-total-amount,
  .finance-report__summary > .finance-report__summary-local-amount {
    > label,
    > span {
      width: 150px;
    }
  }
}

.invoice-page .invoice-custom-fields-content {
  column-gap: 2rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  row-gap: 0.5rem;

  h3, h4 {
    grid-column: 1 / 3;
  }

  h4 {
    font-weight: 600;
  }

  .label {
    grid-column: 1 / 2;
  }

  .value {
    grid-column: 2 / 3;

    .fileuploader-item {
      display: inline-block;
    }
  }
}

.invoice-warning {
  background-color: #fedfdd;
  color: #7a4a50;
}

.page--us-tax-form-review {
  .form-group {
    margin-bottom: 0;

    #field-effectiveDate {
      max-width: 120px;
    }
  }

  .file-preview {
    height: calc(100vh - 414px);

    @media (max-width: map-get($grid-breakpoints, lg)) {
      height: calc(100vh - 474px);
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      height: calc(100vh - 598px);
    }
  }

  form {
    position: relative;

    .form-buttons {
      position: absolute;
      bottom: -105px;
      right: -32px;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        right: -16px;
      }

      @media (max-width: map-get($grid-breakpoints, md)) {
        bottom: -155px;
        flex-flow: wrap;

        button:first-child {
          order: 3;
          margin: 15px 0 0 100%;
        }
      }
    }
  }
}

.us-1099-filing-error {
  background-color: $pale-gray;
}

.us-1099-contractors-year-selector {
  width: 7rem;
}

.user-us-tax-filings-list {
  .card-body > div {
    border-bottom: 1px solid $pale-gray;

    .icon {
      position: relative;

      i.fa-file {
        --fa-primary-color: #{$black};
        --fa-secondary-color: #{$pending};
        --fa-secondary-opacity: 1;
      }

      span {
        font-size: 7px;
        font-weight: 1000;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.us-tax-forms-table tr {
  overflow: visible !important;
  z-index: unset !important;
}

.us-tax-forms-table, .user-us-tax-forms-list {
  colgroup col:last-of-type {
    width: 190px;
  }
}
