$td-table-border-radius: 5px;

.td-table {
  overflow: auto;

  table {
    background-color: $white;

    tbody {
      tr {
        &:hover {
          background-color: $pale-gray-two;
        }

        td {
          padding: 15px 15px;
        }
      }
    }

    tr {
      background-color: $white;

      &.clickable {
        cursor: pointer;
      }

      th {
        color: $gray-900;
        font-weight: $font-weight-bold;
        border-bottom: 1px solid $black;
        height: 50px;
        text-transform: uppercase;
        vertical-align: middle;
        font-size: 12px;
    
        .header-content.empty {
          color: transparent;
        }
      }

      th, td {
        border-right: 1px solid $gray-300;
        text-overflow: initial;
        white-space: normal;
        overflow: visible;
        word-break: break-word;
    
        &:last-of-type, &.expand-cell, &.react-bs-table-expand-cell {
          border-right: 0;
        }
      }

      td.react-bs-table-expand-cell i {
        transition: transform 0.5s;
      }

      &.expanded-row-parent, &.expanded-row {
        background-color: $lightblue;
      }

      &.expanded-row-parent td.react-bs-table-expand-cell i {
        transform: rotate(90deg);
      }
    }

    thead.no-border > tr > th {
      border-width: 0;
    }

    &.striped table tbody tr:nth-of-type(odd) {
      background-color: $pale-gray-four;
    }
  }

  &.bordered {
    table {
      border: 1px solid #ddd;
      border-radius: $td-table-border-radius;
      box-shadow: 0 0 0 1px $gray-300;
      outline: none;

      tr td {
        border-top: none;
      }

      @media (max-width: map-get($grid-breakpoints, xl)) {
        box-shadow: none;
      }

      tr:first-of-type, tr:first-of-type th:first-of-type {
        border-top-left-radius: $td-table-border-radius;
      }

      tr:first-of-type, tr:first-of-type th:last-of-type {
        border-top-right-radius: $td-table-border-radius;
      }

      tr:last-of-type, tr:last-of-type td:first-of-type {
        border-bottom-left-radius: $td-table-border-radius;
      }

      tr:last-of-type, tr:last-of-type td:last-of-type {
        border-bottom-right-radius: $td-table-border-radius;
      }
    }
  }

  &.layout-fixed table {
    table-layout: fixed
  }

  &.is-full-page {
    /* So split button menu is always visible */
    min-height: calc(100vh - 300px);
  }
}

