// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

button:after {
  outline: none;
}

.btn,
button {
  &:focus,
  &:active,
  &:active:hover,
  &:active:focus {
    box-shadow: none;
    outline: none;
  }
}

.btn {
  padding: 12px 20px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: .5px;
  border-width: 1px;
  font-weight: $font-weight-semibold;
  border-radius: $borderRadius;

  &.btn-lg {
    padding: 16px 15px;
    font-size: 14px;
  }

  &.btn-sm {
    padding: 9px 15px;
    font-size: 14px;
  }

  &.btn-xs {
    padding: 6px 10px;
    font-size: 10px;
  }

  .fal,
  .fas {
    font-size: 16px;
    line-height: 1;
  }


  &.disabled,
  &[disabled] {
    opacity: 1;
    cursor: not-allowed;
  }

  .fal + span,
  .fas + span {
    margin-left: 10px;
  }



  span + img,
  span + .fal,
  span + .fas {
    margin-left: 15px;
  }

  &.btn-rounded {
    padding: 2px 15px;
    line-height: 20px;
    font-weight: $font-weight-semibold;
    border-radius: 20px;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    &.btn-sm {
      line-height: 16px;
    }

    .fal,
    .fas {
      font-size: 17px;
      position: relative;
    }

    &.btn--dirty {
      border-color: $td-blue;
    }
  }

  &.btn-default {
    font-weight: $font-weight-semibold;
    color: $black;
    background-color: $white;
    border-color: $graphite-light;

    > i,
    > .fal,
    > .fas {
      vertical-align: middle;
    }

    &.btn--active,
    &.btn--active:focus,
    &.btn--active:active,
    &.btn--active:hover {
      color: $white;
      border-color: $graphite-dark;
      background-color: $graphite-dark;
    }

    &.btn--dirty {
      border-color: $graphite-dark;
      background-color: $graphite-dark;

      &:hover {
        border-color: $graphite-dark;
        background-color: $graphite-dark;
      }
    }

    &:focus,
    &:hover {
      background-color: $white;
      border-color: $graphite-dark;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $graphite-dark;
      border-color: $graphite-dark;
      color: $white;
    }

    &.disabled,
    &[disabled] {
      &,
      &:active,
      &:active:hover,
      &:active:focus {
        color: $btn-default-disabled-color;
        background-color: $white;
        border-color: $graphite-light;
      }
    }
  }

  &.btn-link {
    color: $warm-gray;
    text-transform: none;

    &:focus,
    &:hover {
      text-decoration: none;
      color: $black;
    }

    &.btn-rounded {
      padding-left: 0;
      padding-right: 0;
    }

    &.disabled,
    &[disabled] {
      opacity: 0.65;
    }

    &.btn-icon {
      padding: 5px;
      width: 25px;
      height: 25px;
      background-color: transparent;
      color: $td-blue;
      border-radius: 3px;
      border: none;

      i {
        font-size: 20px;
      }

      &:hover {
        background-color: $td-blue;
        color: $white;
      }

      &:active {
        background-color: $curious-blue;
        color: $white;
      }

      &[disabled] {
        background-color: transparent;
        color: $warm-gray-three;
      }
    }
  }

  &.btn-vote {
    transition: all .4s ease;
    opacity: 1;
    height: auto;
    width: 25px;
    border-radius: 0 3px 3px 0;
    position: relative;
    margin: 0;
    border: 0;

    .fal, .fas {
      font-size: 11px;
      margin-left: 0;
    }

    &.up-vote,
    &.retract-vote {
      padding: 0 0 0 5px;
      .fa-times {
        display: none;
      }
    }

    &.remove-endorsement,
    &.retract-vote {
      .fas {
        display: none;
      }
    }

    &.retract-vote,
    &.remove-endorsement {
      .fal {
        display: none;
      }
    }

    &.up-vote {
      color : $warm-gray;
      .fa-times {
        display: none;
      }
    }

    &.retract-vote {
      color : $td-blue;
      .fal {
        display: block;
      }
    }

    &.remove-endorsement {
      .fa-times {
        display: block;
      }

      span {
        font-size: 11px;
        padding: 0 0 0 5px;
      }
    }

  }

  .status-dot {
    position: absolute;
    top: -4px;
    margin: 0;
    right: -3px;
  }
}

.button-container {
  .btn {
    margin-bottom: 15px;
    min-width: 100px;
  }
}


@media (max-width: map-get($grid-breakpoints, sm)) {
  .buttons-container .btn + .btn {
    margin-left: 0;
  }
}

.btn + .btn,
.btn + .td-dropdown,
.btn-container + .btn-container {
  margin-left: 15px;
}

.btn.pull-right + .btn.pull-right,
.btn-container.pull-right + .btn-container.pull-right {
  margin-right: 15px;
}

.btn-primary {
  background-color: $td-blue;
  border-color: $td-blue;
  color: $white;

  &:focus,
  &:hover {
    background-color: #2AB3BF;
    border-color: #2AB3BF;
  }

  &:active,
  &:active:hover,
  &:active:focus,
  &.active {
    background-color: $graphite-light;
    border-color: $graphite-light;
  }

  &.btn-rounded{
    background-color: $white;
    border-color: $graphite-light;
    color: $black;


    .fal, .fas {
      color: $black;
    }

    &:focus,
    &:hover {
      color: $black;
      background-color: $white;
      border-color: $graphite-dark;

      .fal, .fas{
        color: $black;
      }
    }

    &:active,
    &:active:hover,
    &:active:focus {
      background-color: $graphite-dark;
      border-color: $graphite-dark;
      color: $white;

      .fal, .fas {
        color: $white;
      }
    }
  }

  &.disabled,
  &.btn[disabled] {
    background-color: #11A1AD;
    border-color: #11A1AD;
    color: #006B75;
    opacity: 1;

    &.btn-rounded {
      color: $warm-gray !important;
      background-color: $graphite-light !important;
      border-color: $graphite-light !important;

      .fal, .fas {
        color: $warm-gray !important;
      }
    }
  }
}

.btn-wise {
  background-color: $light-green;
  border-color: $light-green;
  color: $dark-green;

  &:focus,
  &:hover {
    background-color: #87c65f;
    border-color: #87c65f;
  }
}

.btn-success {
  color: $white;
  background-color: $medium-green;
  border-color: $medium-green;

  &:focus,
  &:hover {
    background-color: #39BB6D;
    border-color: #39BB6D;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: #1AAA54;
    border-color: #1AAA54;
  }

  &.disabled,
  &[disabled] {
    &,
    &:active,
    &:active:hover,
    &:active:focus {
      color: #006F2C;
      background-color: #1AAA54;
      border-color: #1AAA54;
    }

    &.btn-rounded{
      .fal, .fas{
        color: #006F2C;
      }
    }
  }
}

.btn-danger {
  &:focus,
  &:hover {
    background-color: #EA3E3F;
    border-color: #EA3E3F;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: #E51E1F;
    border-color: #E51E1F;
  }

  &.disabled,
  &[disabled] {
    &,
    &:active,
    &:active:hover,
    &:active:focus {
      color: $btn-danger-disabled-color;
      background-color: #E51E1F;
    }

    &.btn-rounded{
      .fal, .fas{
        opacity: 0.5;
      }
    }
  }
}

.btn-warning {
  color: $white;

  &:focus,
  &:hover {
    background-color: #EB9F3D;
    border-color: #EB9F3D;
    color: $white;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: #FAB152;
    border-color: #FAB152;
    color: $white
  }

  &.disabled,
  &[disabled] {
    &,
    &:active,
    &:active:hover,
    &:active:focus {
      color: #AC6200;
      background-color: #E18D1F;
      border-color: #E18D1F;
    }

    &.btn-rounded{
      .fal, .fas{
        color: #B46600;
      }
    }
  }
}

.btn-group {
  .btn {
    line-height: 1;
  }

  &--active {
    > .btn {
      border-top-color: $td-blue !important;
      border-bottom-color: $td-blue !important;
      border-right-color: $td-blue !important;

      &:first-child {
        border-left-color: $td-blue !important;
      }

      &:last-child {
        border-right-color: $td-blue !important;
      }
    }
  }
}

.dropdown .btn-default,
.dropup .btn-default {
  border: 1px solid $silver;
  background-color: $white;
  font-size: 14px;

  .caret {
    margin-left: 0;
    right: 15px;
    top: 18px;
    position: absolute;

    &:before {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      left: -6px;
      position: absolute;
      top: -10px;
    }
  }
}

.dropup .btn .caret {
  top: 12px;

  &:before {
    top: 6px;
  }
}

.dropdown .btn .caret:before {
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .btn .caret:before {
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.btn-default.dropdown-toggle,
.btn-default.dropdown-toggle:active {
  color: #303030;
  text-transform: none;
  text-align: left;
  border-radius: $borderRadius;
}

.open {
  > .btn-default.dropdown-toggle,
  > .btn-default.dropdown-toggle:hover,
  > .btn-default.dropdown-toggle:focus {
    background: #fff;
    color: #303030;
    box-shadow: none;
    border-color: $graphite-dark;
  }

  &.dropdown.btn-block.btn-group-vertical > .dropdown-toggle:first-child:not(:last-child) {
    border-radius: 4px 4px 0 0;
  }
}

.dropdown.btn-group-vertical,
.dropup.btn-group-vertical {
  > .dropdown-toggle:first-child:not(:last-child) {
    border-radius: $borderRadius;
  }

  > .dropdown-menu {
    padding: 0;
    width: 100%;
    max-height: 320px;
    overflow: auto;
    border-color: $graphite-dark;
    box-shadow: none;

    li {

      &:nth-child(odd) {
        a {
          background: $pale-gray-two;
        }
      }

      a {
        padding: 8px 14px;
        line-height: 20px;
        font-size: 14px;
        box-shadow: 0 -1px 0 0 $graphite-light;

        &:hover {
          background: #f1f2f3;
        }
      }
    }
  }
}

.dropdown.btn-group-vertical {
  > .dropdown-menu {
    border-top: none;
    border-radius: 0 0 4px 4px;
    margin: -1px 0 0 0;
  }
}

.dropup.btn-group-vertical {
  > .dropdown-menu {
    margin: 0 0 -3px 0;
    border-bottom: none;
    border-radius: 4px 4px 0 0;

    li a {
      box-shadow: 0 -1px 0 0 $graphite-light inset;
    }
  }
}

.dropdown-toggle > .btn-default {
  &:active {
    > img {
      filter: invert(100%);
    }
  }
}
